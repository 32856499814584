import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-btn-modal-suscription',
  templateUrl: './btn-modal-suscription.component.html',
  styleUrls: ['./btn-modal-suscription.component.scss']
})
export class btnModalSuscription implements OnInit {
  @ViewChild('closeBtn', { static: false }) closeBtn!: ElementRef;
  @Output() returnConfirmation: EventEmitter<boolean> = new EventEmitter()

  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit() {
    let if_loaded = sessionStorage.getItem('loaded_vision_preferences') ? true : false
    if (!if_loaded) {
      setTimeout(() => {
        $('#exampleModal').modal('show')
        sessionStorage.setItem('loaded_vision_preferences','true')
      }, 3000);
    }
  }


  onClose(): void {
    this.closeBtn.nativeElement.click()
  }

  saveData(): void {
    this.onClose()
    this.returnConfirmation.emit(true);
  }

  tag(eventCategory, eventAction, eventvalue = '') {

    const gtmTag = {
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventvalue,
      event: 'eventclick'
    }

    this.gtmService.pushTag(gtmTag);
  }
}


