import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { EditPreferencesDialogComponent } from '../../pages/public/edit-preferences-dialog/edit-preferences-dialog.component';
import { SubscribeDialogComponent } from '../../pages/public/subscribe-dialog/subscribe-dialog.component';
import { MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public reportsList: Array<any>;
  public searchText: string;
  public subscribeMenuVisible = false;
  public currentMenuOption: number;
  public categories: any;
  public mainCategories: any;
  public reports: any;
  public reportTypes: any;
  public ready = false;
  public companies: any;
  public currentCategory: any;
  public menuOptions: any = [{
    name: 'Estar actualizado',
    code: 'ESTARACTUALIZADO',
    idx: 1,
  }, {
    name: 'Macroeconomía',
    code: 'MACROECONOMA',
    idx: 2,
  }, {
    name: 'Nuestros indicadores',
    code: 'NUESTROSINDICADORES',
    idx: 3,
  }, {
    name: 'Economías Centroamericanas',
    code: 'ECONOMASCENTROAMERICANAS',
    idx: 4,
  }, {
    name: 'Tendencias sectoriales',
    code: 'TENDENCIASSECTORIALES',
    idx: 5,
  }, {
    name: 'Análisis de compañias',
    code: 'ANLISISDECOMPAAS',
    idx: 6,
  }, {
    name: 'En qué invertir',
    code: 'ENQUINVERTIR',
    idx: 7,
  }];
  public investmentGroups = [{
    name: 'Renta Fija',
    code: 'RENTAFIJA',
    items: [],
    id: null,
    urlid: '618e3e83b29e2a3ba771c23b',
  }, {
    name: 'Acciones',
    code: 'ACCIONES',
    items: [],
    id: null,
    urlid: '618e906bb29e2a3ba771c24d',
  }, {
    name: 'Monedas',
    code: 'DIVISAS',
    items: [],
    id: null,
    urlid: '61a9d45b5b3802dcd38fedb1',
  }];
  items: any;
  constructor(
    private dialog: MatDialog,
    private http: HttpService,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
  }

  ngOnInit() {
    const observables = [this.getCompanies(), this.getCategories()];
    forkJoin(observables).subscribe(() => {
      this.ready = true;
    });
    this.openModalUrl();
  }

  public getItems(menu) {
    if (menu.code === 'ANLISISDECOMPAAS') {
      return this.companies;
    }

    if (!this.categories) {
      return;
    }

    const code = menu.code;
    const category = this.categories.find(e => e.code === code);
    if (!category) {
      return [];
    }

    return category.childrenMainReportTypes.length ? category.childrenMainReportTypes : category.childrenSubReportTypes;
  }

  mouseEnter(idx?: number) {
    if (!this.ready) {
      return;
    }
    this.menuOptions.forEach((menuItem, index) => {
      if ((idx === null) || (index !== (idx - 1))) {
        menuItem.visible = false;
      }
    });

    if (idx !== null) {
      const menu = this.menuOptions[idx - 1];
      this.menuOptions[idx - 1].visible = !!!menu.visible;
    } else {
      this.subscribeMenuVisible = !this.subscribeMenuVisible;
    }
  }

  go(eventName) {
    const gtmTag = {
      event: eventName,
      clickUrl: window.location.href
    };
    this.gtmService.pushTag(gtmTag);
    document.getElementById('mySidenav').style.width = '0';
  }

  getCategoryReportTypeLink(menu: any, report: any) {
    const cat = this.getCategory();
    let urlcat = '';
    if(cat.url){
      urlcat = cat.url;
    }else{
      urlcat = cat.id;
    }

    let urltype = '';
    if(report.url){
      urltype = report.url;
    }else{
      urltype = report.id;
    }

    if (report && report.code === 'ELLIBRO') {
      window.open('https://libro.daviviendacorredores.com/', '_blank');
      return;
    }
    if (report && report.id === '5e7fc9a5dc4b4a6c662951b1') {
      window.open('https://convision.davivienda.com/eventos?utm_source=BTN-Vision-enqueinvertir&utm_medium=redirected&utm_campaign=INVITACION-EVENTO-VIRTUAL&utm_term=BTN-Vision-enqueinvertir-INVITACION-EVENTO-VIRTUAL&utm_content=audienciasdavivienda', '_blank');
      return;
    }

    if(cat.id === '5e7fc9a5dc4b4a6c66295180' && report.id === '5e7fc9a5dc4b4a6c662951a0') {
      return ['/categories', 'tendencias-sectoriales', 'type', report.urltype];
    }
      
    this.router.navigate(['/categories', urlcat, 'type', report.urltype]);
    
    document.getElementById('mySidenav').style.width = '0';
  }

  getReportTypeName(reportType: any, category: any) {
    if (reportType && reportType.aliases) {
      const alias = reportType.aliases;
      if (alias[category.id]) {
        return alias[category.id];
      }
    }
    // idarevalos, forzado edición de nombre
    reportType.description = this.forceEditNameMenu(reportType)
    return reportType.description;
    
  }

  getQueryParams(gp) {
    return {subcategory: gp.id};
  }

  getCategory(option?: number): any {
    const idx = option ? option : this.currentMenuOption;
    if (!this.categories) {
      return null;
    }
    const menu = this.menuOptions[idx - 1];
    const cat = this.categories.find(e => e.code === menu.code);
    return cat ? cat : null;
  }

  getCategoryLink(option?: number) {
    const cat = this.getCategory(option);
    if (cat !== null) {
      if(cat.url){
        return '/'+cat.url;
      }else{
        return ['/categories', cat.id];
      }
      
    }

    return ['/categories', option];
  }

  getNewSubCategoryLink(item: any, gp? : any){
    if (item && item.code === 'ELLIBRO') {
      window.open('https://libro.daviviendacorredores.com/', '_blank');
    }else if (item && item.id === '5e7fc9a5dc4b4a6c662951b1'){
      window.open('https://convision.davivienda.com/eventos?utm_source=BTN-Vision-enqueinvertir&utm_medium=redirected&utm_campaign=INVITACION-EVENTO-VIRTUAL&utm_term=BTN-Vision-enqueinvertir-INVITACION-EVENTO-VIRTUAL&utm_content=audienciasdavivienda', '_blank');
    }else{
      this.http.get({
        path: `public/urls/`,
        data: {where: { subcategoryId: item.id, component: "subcategories" } },
        encode: true
      }).subscribe(
      (response) => {
        if(response){
          if(Object.keys(response.body).length){ 
            this.http.get({
              path: `public/urls/`,
              data: {where: { categoryId: response.body[0].categoryId, component: "categories" } },
              encode: true
            }).subscribe(
            (response2) => {
              if(response2){
                if(Object.keys(response2.body).length){ 
                  if(gp){
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { id: ""+gp.urlid} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if(response3){
                        if(Object.keys(response3.body).length){
                          this.router.navigate(["/"+response2.body[0].url+"/"+response3.body[0].url+"/"+response.body[0].url]);
                        }
                      }
                    });
                  }else{
                    this.router.navigate(["/"+response2.body[0].url+"/"+response.body[0].url]);
                  }
                }
              }
            });
          }
        }
      });
      document.getElementById('mySidenav').style.width = '0';
    }
  }

  private getReportTypes(): Observable<any> {
    return this.http.get({
      path: `public/reports_type/`,
      data: {
        include: ['mainCategory', 'subCategory']
      },
      encode: true
    }).pipe(
      map((res) => {
        this.reportTypes = res.body;
        return res;
      })
    );
  }

  private getCategories(): Observable<any> {
    return this.http.get({
      path: `public/categories/`,
      data: {
        where: {},
        include: [{
          relation: 'childrenMainReportTypes',
          scope: {
            include: [
              'subCategory'
            ]
          }
        }, 'childrenSubReportTypes', {
          relation: 'children',
          scope: {
            include: ['childrenMainReportTypes', 'childrenSubReportTypes'],
            order: 'description ASC'
          }
        }]
      },
      encode: true
    }).pipe(
      map((res) => {
        this.categories = res.body;
        this.categories = this.categories.map((category) => {
          const params = category && category.params ? category.params : {};
          const alphabetic = params.sorting && params.sorting === 'alphabetic';
          category.childrenMainReportTypes = category.childrenMainReportTypes.map((reportType) => {
            const desc = this.getReportTypeName(reportType, category);
            reportType.description = desc;
            return reportType;
          }).sort((a, b) => {
            if (alphabetic) {
              const nameA = a.description.toLowerCase();
              const nameB = b.description.toLowerCase();
              if (nameA > nameB) {
                return 1;
              } else if (nameA < nameB) {
                return -1;
              } else {
                return 0;
              }
            } else {
              if (a.order > b.order) {
                return 1;
              } else if (a.order < b.order) {
                return -1;
              } else {
                return 0;
              }
            }
          });

          category.childrenSubReportTypes = category.childrenSubReportTypes.map((reportType) => {
            const desc = this.getReportTypeName(reportType, category);
            reportType.description = desc;
            return reportType;
          }).sort((a, b) => {
            if (alphabetic) {
              const nameA = a.description.toLowerCase();
              const nameB = b.description.toLowerCase();
              if (nameA > nameB) {
                return 1;
              } else if (nameA < nameB) {
                return -1;
              } else {
                return 0;
              }
            } else {
              if (a.order > b.order) {
                return 1;
              } else if (a.order < b.order) {
                return -1;
              } else {
                return 0;
              }
            }
          });
          return category;
        });

        this.investmentGroups = this.investmentGroups.map(inv => {
          const category = this.categories.find(cat => cat.code === inv.code);
          inv.id = category.id;
          return inv;
        });
        this.mainCategories = this.categories.filter(e => !e.parentId);
        return res;
      })
    );
  }

  private getCompanies(): Observable<any> {
    return this.http.get({
      path: `public/companies/`,
      data: {
        order: 'name ASC'
      },
      encode: true,
    }).pipe(
      map((res) => {
        this.companies = res.body;
        return res;
      })
    );
  }

  private getReports() {
    this.http.get({
      path: `public/reports-es/`,
      data: {
        where: {
          id: {
            inq: this.categories.filter(e => e.mainReportId).map(e => e.mainReportId)
          }
        },
        fields: ['id', 'name', 'smartContent', 'rTitle', 'reportTypeId', 'publishedAt'],
        include: [{
          relation: 'reportType',
          scope: {
            include: ['mainCategory', 'subCategory']
          }
        }]
      },
      encode: true
    }).subscribe((res) => {
      this.reports = res.body;
    });
  }

  onLoadReports() {
    this.http.get({
      path: 'public/reports-es',
      data: {
        include: ['user', 'reportType', {
          relation: 'reportType',
          scope: {
            include: ['mainCategory', 'subCategory']
          }
        }],
        where: {
          stateId: {inq: ['5e068c81d811c55eb40d14d0','60efec016a6e535657be71a4']}
        }
      },
      encode: true
    }).subscribe((resp: any) => {
      this.reportsList = resp.body;
      this.onLoadMultimedia(this.reportsList);
    });
  }

  onLoadMultimedia(list) {
    this.http.get({
      path: 'public/contents',
      data: {
        include: ['lastUpdater'],
        where: {
          key: 'multimedia'
        }
      },
      encode: true
    }).subscribe((resp: any) => {

    });
  }

  redirectSelection(event) {
    const location = event.multimediaType ? '/multimedia' : '/reports';
    if (event && event.id) {
      this.router.navigate([location, event.id]);
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    const publishedDate = new Date(item.publishedAt);
    const dateString = `${publishedDate.getDate()}/${publishedDate.getMonth() + 1}/${publishedDate.getFullYear()}`;
    const name = item.name ? 'name' : 'title';
    const author = item.name ? 'user' : 'lastUpdater';
    return item[name].toLowerCase().indexOf(term) > -1 ||
      item[author].name.toLowerCase().indexOf(term) > -1 ||
      dateString.indexOf(term) > -1 ||
      item.reportType.mainCategory.find(e => e.description.toLowerCase().indexOf(term) > -1);
  }

  searchEvent() {
    this.router.navigate(['/search'], {queryParams: {s: this.searchText}});
    document.getElementById('mySidenav').style.width = '0';
  }

  subscribeEvent(eventCategory, eventAction, eventLabel){
    this.subscribeMenuVisible = !this.subscribeMenuVisible;
    this.tag(eventCategory, eventAction, eventLabel);
  }

  openDialog() {
    const dialogRef = this.dialog.open(EditPreferencesDialogComponent, {
      width: '350px',
      data: {preferences: true},
      panelClass: 'custom-modalbox',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.subscriber) {
        this.router.navigate(['/edit_confirmation']);
      }
    });
  }

  openQuincenalDialog() {
    const dialogRef = this.dialog.open(SubscribeDialogComponent, {
      width: '350px',
      data: {
        quincenal: true
      },
      panelClass: 'custom-modalbox',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.subscriber) {
        this.router.navigate(['/sub2factor_confirmation']);
      }
    });
  }

  tag(eventCategory, eventAction, eventLabel) {
    if(eventLabel == '' || eventLabel == null){
      eventLabel = window.location.href;
    }
    const gtmTag = {
     
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventLabel,
      event: 'eventclick'
    };
    this.gtmService.pushTag(gtmTag);
  }

  openModalUrl(){
    let open = window.location.search;
    switch (open) {
      case '?openmodal=quincenal':
        this.openQuincenalDialog();
      break;

      case '?openmodal=edit':
        this.openDialog();
      break;
    }
  }
  
  getNewChildCategoryLink(gp: any){
    this.http.get({
      path: `public/urls/`,
      data: {where: { id: ""+gp.urlid} },
      encode: true
    }).subscribe(
    (response) => {
      if(response){
        if(Object.keys(response.body).length){

          this.http.get({
            path: `public/urls/`,
            data: {where: { categoryId: response.body[0].categoryId, component: "categories" } },
            encode: true
          }).subscribe(
          (response2) => {
            if(response2){
              if(Object.keys(response2.body).length){
                this.router.navigate(["/"+response2.body[0].url+"/"+response.body[0].url]);
              }
            }
          });
        }
      }
    });
  }


  /**
   * idarevalos
   * 240506
   * forzado de edicion de opcion de menu para mobile
   */
  forceEditNameMenu(item:any){
    let 
      names = {
        '5e7fc9a5dc4b4a6c662951b1' :'Eventos',
        '5e7fc9a5dc4b4a6c6629518f':'Minería y petróleo'
      },
      resultName = item.description
      
    if (names[item.id]) {
      resultName = names[item.id]
    }
    return resultName
  }


}

