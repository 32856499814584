import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {map} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { couldStartTrivia } from 'typescript';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  host: { class: 'top-menu' }
})

export class TopMenuComponent implements OnInit {
  public subMenuVisible = false;
  public currentMenuOption: number;
  public currentCategory: any;
  public currentReport: any;
  public currentReportURL: string;
  public ready = false;

  public menuOptions = [{
    name: 'Estar actualizado',
    code: 'ESTARACTUALIZADO',
    id: '5e7fc9a5dc4b4a6c6629517e',
    url: null
  }, {
    name: 'Macroeconomía',
    code: 'MACROECONOMA',
    id: '5e7fc9a5dc4b4a6c6629517e',
    url: null
  }, {
    name: 'Nuestros indicadores',
    code: 'NUESTROSINDICADORES',
    id: '5e7fc9a5dc4b4a6c66295180',
    url: null
  }, {
    name: 'Economías Centroamericanas',
    code: 'ECONOMASCENTROAMERICANAS',
    id: '5e7fc9a5dc4b4a6c66295181',
    url: null
  }, {
    name: 'Tendencias sectoriales',
    code: 'TENDENCIASSECTORIALES',
    id:'5e7fc9a5dc4b4a6c66295182',
    url: null
  }, {
    name: 'Análisis de compañías',
    code: 'ANLISISDECOMPAAS',
    id: '5e7fc9a5dc4b4a6c66295183',
    url: null
  }, {
    name: 'En qué invertir',
    code: 'ENQUINVERTIR',
    id: '5e7fc9a5dc4b4a6c66295184',
    url: null
  }, {
    name: 'Multimedia',
    code: 'MULTIMEDIA'
  }];
  public customMenuOptions = [
    {
        "id": "custom_url_1",
        "url": "https://convision.davivienda.com/calendario-financieron-dividendos?utm_source=VISION-DAVIVIENDA",
        "name": "Calendario",
        "category":"5e7fc9a5dc4b4a6c6629517e"
    }
  ]

  readonly  DEFAULT_ITEMS_PER_GROUP = 5;

  public companies: any;
  public categories: any;
  public reports: any;
  public reportTypes: any;

  public totalGroups;
  public itemGroups = [[], [], []];
  public investmentGroups = [{
    name: 'Renta Fija',
    code: 'RENTAFIJA',
    items: [],
    id: null,
    urlid: '618e3e83b29e2a3ba771c23b',
  }, {
    name: 'Acciones',
    code: 'ACCIONES',
    items: [],
    id: null,
    urlid: '618e906bb29e2a3ba771c24d',
  }, {
    name: 'Monedas',
    code: 'DIVISAS',
    items: [],
    id: null,
    urlid: '61a9d45b5b3802dcd38fedb1',
  }];

  public menuTimer: any;
  total: any;

  constructor(private http: HttpService, private router: Router, private gtmService: GoogleTagManagerService) {
    router.events.subscribe((val) => {
      this.mouseLeave();
    });
  }

  ngOnInit() {
    const observables = [this.getCompanies(), this.getCategories(), this.getReportTypes()];
    forkJoin(observables).subscribe(() => {
      this.ready = true;
    });

    this.menuOptions.forEach(e => {
      this.http.get({
        path: `public/urls/`,
        data: {where: { categoryId: e.id, component: "categories" } },
        encode: true
      }).subscribe(
      (response) => {
        if(response){
          if(Object.keys(response.body).length){
            e.url = "/"+response.body[0].url;
          }
        }
      });
      
    });
    
    //this.currentMenuOption = 2;
  }

  go(eventName) {

    const gtmTag = {
      event: eventName,
      clickUrl: window.location.href
    };
    this.gtmService.pushTag(gtmTag);
  }

  mouseEnter(idx?: number) {
    if (!this.ready) {
      return;
    }

    if (this.subMenuVisible) {
      return this.mouseEnterAfterSeconds(idx);
    }

    this.menuTimer = setTimeout(() => {
      this.mouseEnterAfterSeconds(idx);
    }, 500);
  }

  mouseEnterAfterSeconds(idx?: number) {
    if (!this.ready) {
      return;
    }

    this.subMenuVisible = true;

    if (idx !== null) {
      const oldIdx = this.currentMenuOption;
      this.currentMenuOption = idx;

      const code = this.menuOptions[idx - 1].code;
      const category = this.categories.find(e => e.code === code);
      this.currentCategory = category;
      this.currentReport = null;

      if (this.reports) {
        this.currentReport = this.reports.find(e => this.currentCategory.mainReportId &&
          e.id === this.currentCategory.mainReportId);
      }

      if(this.currentReport !== null) {
        if(this.currentReport.url) {
          this.currentReportURL = this.currentReport.url; 
        }else{
          this.currentReportURL = this.currentReport.id;
        }
      }
      if (oldIdx !== this.currentMenuOption) {
        this.distributeItems(false);
      }
    }
  }

  private getItems() {
    if (this.currentMenuOption === 6) {
      return this.companies.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA > nameB) {
            return 1;
          } else if (nameA < nameB) {
            return -1;
          } else {
            return 0;
          }
      });
    }

    const code = this.menuOptions[this.currentMenuOption - 1].code;
    const category = this.categories.find(e => e.code === code);
    if (!category) {
      return [];
    }

    const params = this.currentCategory && this.currentCategory.params ? this.currentCategory.params : {};
    const alphabetic = params.sorting && params.sorting === 'alphabetic';
       
    const reportTypes = this.reportTypes.filter(e => e.mainCategory.find(k => k.id === category.id))
      .map(e => {       
        const item = e;
        item.name = item.description;
        return item;
      }).sort((a, b) => {
        if (alphabetic) {
          const nameA = this.getReportTypeName(a).toLowerCase();
          const nameB = this.getReportTypeName(b).toLowerCase();
          if (nameA > nameB) {
            return 1;
          } else if (nameA < nameB) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a.order > b.order) {
            return 1;
          } else if (a.order < b.order) {
            return -1;
          } else {
            return 0;
          }
        }
      });

       /**
     * add categories manually
     * idarevalos
     * 240529
     * category.id = 5e7fc9a5dc4b4a6c6629517e | Estar Actualizado
     */
    let 
      if_add_item = false

    this.customMenuOptions.forEach( option_ => {
        if (option_.category == category.id) {
          if_add_item = true
          reportTypes.push(option_)
        }
    });
    /** end idarevalos */
    return reportTypes;
  }

  getReportTypeName(reportType: any) {
    if (reportType && reportType.aliases) {
      const alias = reportType.aliases;
      if (alias[this.currentCategory.id]) {
        return alias[this.currentCategory.id];
      }
    }
    // idarevalos, forzado edición de nombre
    reportType.name = this.forceEditNameMenu(reportType)
    return reportType.name;
  }

  private getReportTypes(): Observable<any> {
    return this.http.get({
      path: `public/reports_type/`,
      data: {
        include: ['mainCategory', 'subCategory']
      },
      encode: true
    }).pipe(
      map((res) => {
        this.reportTypes = res.body;
        return res;
      })
    );
  }

  private getCategories(): Observable<any> {
    return this.http.get({
      path: `public/categories/`
    }).pipe(
      map((res) => {
        this.categories = res.body;
        this.getReports();
        return res;
      })
    );
  }

  public getCategoryName(reportType) {
    console.log(reportType)
    return reportType.description;
    // const subCategory = reportType.subCategory && reportType.subCategory.length ? reportType.subCategory[0] : null;
    // if (subCategory) {
    //   return subCategory.description;
    // }
    // const category = reportType.mainCategory && reportType.mainCategory.length ? reportType.mainCategory[0] : null;
    // return category ? category.description : '';
  }

  private getReports() {
    this.http.get({
      path: `public/reports-es/`,
      data: {
        where: {
          id: {
            inq: this.categories.filter(e => e.mainReportId).map(e => e.mainReportId)
          }
        },
        fields: ['id', 'name', 'smartContent', 'rTitle', 'reportTypeId', 'publishedAt', 'url', 'companyId'],
        include: [{
          relation: 'reportType',
          scope: {
            include: ['mainCategory', 'subCategory']
          }
        }]
      },
      encode: true
    }).subscribe((res) => {
      this.reports = res.body;
      this.reports.forEach(report => {
        const companyName = this.companies ? this.companies.find(e => e.id === report.companyId) : null;
        report.companyName = companyName ? companyName.name : report.reportType.description;
      })
    });
  }

  private getCompanies(): Observable<any> {
    return this.http.get({
      path: `public/companies/`,
      data: {
        order: 'name ASC'
      },
      encode: true,
    }).pipe(
      map((res) => {
        this.companies = res.body;
        return res;
      })
    );
  }

  public distributeItems(showAll: boolean) {
    const items = this.getItems();
    this.total = items.length;

    this.totalGroups = 2;
    if (this.currentMenuOption === 6 || this.currentMenuOption === 7) {
      this.totalGroups = 3;
    }

    let itemsPerGroup = Math.ceil(this.total / this.totalGroups);
    itemsPerGroup = !showAll ? Math.min(this.DEFAULT_ITEMS_PER_GROUP, this.total) : itemsPerGroup;

    this.itemGroups = this.itemGroups.map(e => []);

    let idx = 0;
    let cnt = 0;

    if (this.currentMenuOption === 7) {
      this.investmentGroups = this.investmentGroups.map(e => {
        const subitems = items.filter(k => k.subCategory.find(x => x.code === e.code));
        e.items = subitems;
        e.id = subitems ? subitems[0].subCategory[0].id : '';
        return e;
      });
      return;
    }

    while (idx < this.totalGroups) {
      while (this.itemGroups[idx].length < itemsPerGroup && cnt < items.length) {
        this.itemGroups[idx].push(items[cnt]);
        cnt++;
      }
      idx++;
    }
  }

  mouseLeave() {
    this.subMenuVisible = false;

    if (this.menuTimer) {
      window.clearTimeout(this.menuTimer);
    }
  }

  openExternal(item) {
    if (item && item.code === 'ELLIBRO') {
      window.open('https://libro.daviviendacorredores.com/', '_blank');
    }
    if (item && item.id === '5e7fc9a5dc4b4a6c662951b1'){
      window.open('https://convision.davivienda.com/eventos?utm_source=BTN-Vision-enqueinvertir&utm_medium=redirected&utm_campaign=INVITACION-EVENTO-VIRTUAL&utm_term=BTN-Vision-enqueinvertir-INVITACION-EVENTO-VIRTUAL&utm_content=audienciasdavivienda', '_blank');
    }

  }

  getCategory(option?: number): any {
    const idx = option ? option : this.currentMenuOption;
    if (!this.categories) {
      return null;
    }
    const cat = this.categories.find(e => e.code === this.menuOptions[idx - 1].code);
    return cat ? cat : null;
  }

  getNewCategoryLink(id){
    this.http.get({
      path: `public/urls/`,
      data: {where: { categoryId: id, component: "categories" } },
      encode: true
    }).subscribe(
    (response) => {
      if(response){
        if(Object.keys(response.body).length){
          this.router.navigate(["/"+response.body[0].url]);
        }
      }
    });
  }

  getNewChildCategoryLink(gp: any){
    this.http.get({
      path: `public/urls/`,
      data: {where: { id: ""+gp.urlid} },
      encode: true
    }).subscribe(
    (response) => {
      if(response){
        if(Object.keys(response.body).length){

          this.http.get({
            path: `public/urls/`,
            data: {where: { categoryId: response.body[0].categoryId, component: "categories" } },
            encode: true
          }).subscribe(
          (response2) => {
            if(response2){
              if(Object.keys(response2.body).length){
                this.router.navigate(["/"+response2.body[0].url+"/"+response.body[0].url]);
              }
            }
          });
        }
      }
    });
  }

  getNewSubCategoryLink(item: any, gp? : any){
       
    /**
     * custom url to custom options
     * idarevalos | 240529
     */
    let 
      custom_url = false
    this.customMenuOptions.forEach(option_ => {
        if (option_.id == item.id) {
          custom_url = item.url
        }
    });
    // si se encontro url
    if (custom_url) {
      window.open(custom_url,'_blank')
    }
    /** end idarevalos */

    this.http.get({
      path: `public/urls/`,
      data: {where: { subcategoryId: item.id, component: "subcategories" } },
      encode: true
    }).subscribe(
    (response) => {
      if(response){
        if(Object.keys(response.body).length){ 
          this.http.get({
            path: `public/urls/`,
            data: {where: { categoryId: response.body[0].categoryId, component: "categories" } },
            encode: true
          }).subscribe(
          (response2) => {
            if(response2){
              if(Object.keys(response2.body).length){ 
                if(gp){
                  this.http.get({
                    path: `public/urls/`,
                    data: {where: { id: ""+gp.urlid} },
                    encode: true
                  }).subscribe(
                  (response3) => {
                    if(response3){
                      if(Object.keys(response3.body).length){
                        this.router.navigate(["/"+response2.body[0].url+"/"+response3.body[0].url+"/"+response.body[0].url]);
                      }
                    }
                  });
                }else{
                  this.router.navigate(["/"+response2.body[0].url+"/"+response.body[0].url]);
                }
              }
            }
          });
        }
      }
    });
  }

  isMenuActive(){

  }
  getCategoryLink(option?: number) {
    const cat = this.getCategory(option);
    if (cat !== null) {
      if(cat.url){
        return ['/categories', cat.url];
      }else{
        return ['/categories', cat.id];
      }
      
    }

    return ['/categories', option];
  }

  getQueryParams(gp) {
    return {subcategory: gp.id};
  }

  getCategoryReportTypeLink(report: any) {
    const cat = this.getCategory();
    let urlcat = '';
    if(cat.url){
      urlcat = cat.url;
    }else{
      urlcat = cat.id;
    }

    let urltype = '';
    if(report.url){
      urltype = report.url;
    }else{
      urltype = report.id;
    }

    if (report && report.code === 'ELLIBRO') {
      return null;
    }

    if (report && report.id === '5e7fc9a5dc4b4a6c662951b1'){
      return null;
    }

    if(cat.id === '5e7fc9a5dc4b4a6c66295180' && report.id === '5e7fc9a5dc4b4a6c662951a0') {
      return ['/categories', 'tendencias-sectoriales', 'type', report.urltype];
    }

    const rsp = ['/categories', urlcat, 'type', report.urltype];
    return rsp;
  }

  tag(eventCategory, eventAction='', eventLabel='', getUrl=null) {
    (getUrl) ? eventLabel = window.location.origin + eventLabel : eventLabel = window.location.href;
    const gtmTag = {
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventLabel,
      event: 'eventclick'
    };
    this.gtmService.pushTag(gtmTag);
  }

  getReportLink(reportId){

    this.http.get({
      path: `public/reports/`,
      data: { where: {id: reportId}},
      encode: true
    }).subscribe((res) => {
      if (res.body && (res.body as any).length) {
        let report =  res.body[0];

        this.http.get({
          path: `public/urls/`,
          data: {where: { reportId: ""+report.id} },
          encode: true
        }).subscribe(
        (response) => {
          if (response) {
            
            let last = "";
            if(Object.keys(response.body).length){
              last = response.body[0].url+"/";
            }else{
              last = report.id+"/";
            }

            let subcat;
            if(report.companyId != "null"){
              subcat = report.companyId;
            }else{
              subcat = report.reportTypeId;
            }
            
            this.http.get({
              path: `public/urls/`,
              data: {where: { subcategoryId: ""+ subcat} },
              encode: true
            }).subscribe(
            (response2) => {
              if (response2) {
                if(Object.keys(response2.body).length){
                  let middle;
                  if(response2.body[0].fatherUrlId){
                    // 4 Params
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { id: ""+ response2.body[0].fatherUrlId} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          middle =  response3.body[0].url+"/"+response2.body[0].url+"/";

                          let beg;
                          this.http.get({
                            path: `public/urls/`,
                            data: {where: { categoryId: ""+ response3.body[0].categoryId, component: "categories"} },
                            encode: true
                          }).subscribe(
                          (response4) => {
                            if (response4) {
                              if(Object.keys(response4.body).length){
                                beg = response4.body[0].url+"/";
                                this.router.navigate([beg+middle+last]);
                              }
                            }
                          });
                        }
                      }
                    });

                  }else{
                    // 3 Params
                    middle = response2.body[0].url+"/";

                    let beg;
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { categoryId: ""+ response2.body[0].categoryId, component: "categories"} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          beg = response3.body[0].url+"/";
                          this.router.navigate([beg+middle+last]);
                        }
                      }
                    });
                    
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  /**
   * idarevalos
   * 240208
   * forzado de edicion de opcion de menu
   */
  forceEditNameMenu(item:any){
    let 
      names = {
        '5e7fc9a5dc4b4a6c662951b1' :'Eventos',
        '5e7fc9a5dc4b4a6c6629518f':'Minería y petróleo'
      },
      resultName = item.name
    if (names[item.id]) {
      resultName = names[item.id]
    }
    return resultName
  }

}
